import axios from "axios";
import React from "react";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import MetaTags from "react-meta-tags";

import "./Resume.css";

function App() {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios({
      url: "https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clp2t4u1t0kvv01ta9lifesaa/master",
      method: "post",
      data: {
        query: `
                query site {
                    snippets {
                      legal {
                        markdown
                      }
                      aboutMe {
                        markdown
                      }
                      photo {
                        url
                      }
                      aboutMeTitle
                      corporateLogo {
                        url
                      }
                      services {
                        title
                        descriptionHtml {
                          markdown
                        }
                      }
                      servicesTitle
                      metaTags {
                        tagName
                        value
                      }
                      pageTitle
                      closingWordTitle
                      closingWord {
                        markdown
                      }
                    }
                  }
                  `,
      },
    }).then((response) => {
      setData(response.data);
    });
  }, []);

  return (
    <>
      <MetaTags>
        <title>{data?.data?.snippets?.[0]?.pageTitle}</title>
        {data?.data?.snippets?.[0]?.metaTags.map((tag) => {
          return <meta name={tag.tagName} content={tag.value} />;
        })}
      </MetaTags>
      <div className="container w-container">
        <div className="w-row">
          <div className="w-col w-col-4 w-col-stack">
            <div className="author-wrapper">
              <div className="person-image">
                <img src={data?.data?.snippets?.[0]?.photo.url} alt="" />
              </div>
              <div className="social-wrapper">
                <div className="left-copyright-flex">
                  <ReactMarkdown>
                    {data?.data?.snippets?.[0]?.legal.markdown}
                  </ReactMarkdown>
                </div>

                <a
                  href="https://www.linkedin.com/in/pietervanhuffel"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={icon({ name: "linkedin", style: "brands" })}
                    size="3x"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-col w-col-8 w-col-stack">
            <div className="page-content">
              <div style={{ paddingBottom: "30px" }}>
                <img
                  src={data?.data?.snippets?.[0]?.corporateLogo.url}
                  width="50%"
                  alt=""
                />
              </div>
              <div className="title-wrapper">
                <h3 className="resume-title">
                  01. {data?.data?.snippets?.[0]?.aboutMeTitle}
                </h3>
              </div>
              <div>
                <ReactMarkdown>
                  {data?.data?.snippets?.[0]?.aboutMe.markdown}
                </ReactMarkdown>
              </div>
              <div className="space-divider"></div>
              <>
                <div className="title-wrapper">
                  <h3 className="resume-title">
                    02. {data?.data?.snippets?.[0]?.servicesTitle}
                  </h3>
                </div>
                <div>
                  <div className="w-dyn-list">
                    <div role="list" className="w-dyn-items">
                      {data?.data?.snippets?.[0]?.services?.map((service) => {
                        return (
                          <div role="listitem" className="w-dyn-item">
                            <div className="education-wrapper">
                              <div className="w-row">
                                <div>
                                  <div className="education-name">
                                    {service.title}
                                  </div>

                                  {/* <div className="education-keywords">
                                      <strong>Keywords:</strong>{" "}
                                      {service?.keywords}
                                    </div> */}
                                </div>
                                <div>
                                  <ReactMarkdown>
                                    {service.descriptionHtml.markdown}
                                  </ReactMarkdown>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
              <div className="title-wrapper">
                <h3 className="resume-title">
                  {data?.data?.snippets?.[0]?.closingWordTitle}
                </h3>
                <ReactMarkdown>
                  {data?.data?.snippets?.[0]?.closingWord.markdown}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
